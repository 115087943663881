import React from 'react'

function Blockline() {
  return (
    <div>
       <div className="w-auto mt-20  sm:mx-[95px]">
        <div className='border'></div>
       </div>
    </div>
  )
}

export default Blockline
